<template>
<li class="cards__item">
  <div class="cards__img">
    <img class="cards__img-pic" alt="cards_image" :src="cardsItem.picture">
  </div>
  <div class="cards__desc">
    <div class="cards__name">
      {{ cardsItem.name }}
    </div>
    <a class="cards__link" rel="noopener" target="_blank"
     :href="`http://${cardsItem.link}`">{{cardsItem.link}}</a>
  </div>
</li>
</template>

<script>
export default {
  props: {
    cardsItem: {
      type: Object,
      required: true,
    },
  },
  components: {},
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
$white: #FFFFFF;
$dark-gray: #2B3138;
$light-gray: #D5D6D7;
$purple: #8293E8;
$yellow: #F8A81B;
$green: #27AE60;
$blue:#4DB6BC;

.cards {
  &__desc {
    @media (max-width: 525px) {
      text-align: center;
    }
  }

  &__img {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    height: 200px;
    width: 200px;

    @media (max-width: 890px) {
      width: 150px;
      height: 150px;
    }

    @media (max-width: 720px) {
      width: 140px;
      height: 140px;
    }

    @media (max-width: 590px) {
      width: 160px;
      height: 160px;
    }

    @media (max-width: 525px) {
      width: 180px;
      height: 180px;
      margin: 0 auto;
    }

    @media (max-width: 360px) {
      width: 140px;
      height: 140px;
    }

    &-pic {
      width: 100%;
      object-fit: cover;
      height: 100%;
      display: block;
    }
  }

  &__item {
    margin: 0 25px 0 0;
    width: 310px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;

    .client__cards & {
      align-items: center;
    }

    .partners__cards & {
      height: 390px;

      @media (max-width: 1170px) {
        height: 380px;
        width: 260px;
      }

      @media (max-width: 1080px) {
        width: 230px;
      }

      @media (max-width: 980px) {
        width: 310px;
        align-items: center;
        text-align: center;
      }

      @media (max-width: 890px) {
        height: 300px;
      }

      @media (max-width: 590px) {
        height: 310px;
      }
    }

    &:last-child {
      margin-right: 0;
    }

    @media (max-width: 1130px) {
      width: 250px;
    }

    @media (max-width: 850px) {
      width: 200px;
    }

    @media (max-width: 720px) {
      margin: 0 25px 0 0;
    }

    @media (max-width: 525px) {
      margin: 0;
      width: 250px;
    }
  }

  &__name {
    font-size: 18px;
    line-height: 22px;
    font-family: 'montserratbold', sans-serif;
    color: #808388;
    padding: 20px 0;

    @media (max-width: 790px) {
      font-size: 14px;
      line-height: 18px;
    }
  }

  &__link {
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: #AAADAF;
    text-decoration: underline;
  }
}
</style>
