<template>
<div class="main-page">

  <banner @showModal="showModalWindow()" />

  <products />

  <news />

  <industries />

  <video-component :mainPage="mainPage" />

  <statistics />

  <clients/>

  <partners/>

  <footer-form />

  <modal-mask-mini class="modal" :showModal="showModal" @closeModal="showModal = false">
    <NewYearModal></NewYearModal>
  </modal-mask-mini>

</div>
</template>

<script>
import Banner from '@/components/mainPage/MainBanner.vue';
import Statistics from '@/components/mainPage/MainStatistics.vue';
import Industries from '@/components/mainPage/MainIndustries.vue';
import Products from '@/components/mainPage/MainProducts.vue';
import News from '@/components/mainPage/MainNews.vue';
import VideoComponent from '@/components/mainPage/MainVideo.vue';
import FooterForm from '@/components/footer/FooterForm.vue';
import Clients from '@/components/mainPage/MainClients.vue';
import Partners from '@/components/mainPage/MainPartners.vue';
import ModalMaskMini from '@/components/shared/ModalMaskMini.vue';
import NewYearModal from '@/components/mainPage/NewYearModal.vue';

export default {
  emits: ['closeModal', 'showModal'],
  name: 'HomeView',
  data() {
    return {
      mainPage: true,
      showModal: false,
    };
  },
  components: {
    Statistics,
    Industries,
    Products,
    News,
    VideoComponent,
    Banner,
    FooterForm,
    Clients,
    Partners,
    ModalMaskMini,
    NewYearModal,
  },
  methods: {
    showModalWindow() {
      this.showModal = true;
    },
  },
};
</script>
