<template>
<section class="main-news">
  <left-background />

  <div class="main-container main-news__container">
    <h1 class="title title--right">
      Новости
    </h1>

    <div class="main-news__content">
      <transition-group class="main-news__list" tag="ul" name="list">
        <li class="main-news__item" v-for="news in slisedSlides" :key="news.id">
          <router-link class="main-news__link" :to="`/news/${news.id}_${news.url}`">
            <div class="main-news__img">
              <img class="main-news__img-pic" alt="news-image" :src="news.picture">
            </div>

            <div class="main-news__bottom">
              <div class="main-news__desc">
                <h3 class="main-news__name">
                  {{news.title}}
                </h3>
                <p class="main-news__text">
                  {{news.short}}
                </p>
              </div>
              <div class="main-news__details">
                <div class="main-news__data">{{news.date}}</div>
                <div class="main-news__link-text">
                  Узнать больше ›
                </div>
              </div>
            </div>
          </router-link>
        </li>
      </transition-group>

      <slider-controls class="slider-controls" :centerBtn="centerBtn"
       @prev="slide('prev')" @next="slide('next')">
        читать все новости
      </slider-controls>
    </div>
  </div>
</section>
</template>

<script>
import LeftBackground from '@/components/shared/background/LeftBg.vue';
import SliderControls from '@/components/shared/sliders/SliderControls.vue';
import axios from 'axios';

export default {
  data() {
    return {
      interval: 8000,
      errored: false,
      newsItem: [],
      window: {
        width: 0,
      },
      centerBtn: {
        show: true,
        centerBtnPath: 'news',
      },
    };
  },
  created() {
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
  },
  unmounted() {
    window.removeEventListener('resize', this.handleResize);
  },
  computed: {
    slisedSlides() {
      return [...this.newsItem].splice(0, 3);
    },
  },
  methods: {
    slide(direction) {
      const lastItem = this.newsItem[this.newsItem.length - 1];

      switch (direction) {
        case 'next':
          this.newsItem.push(this.newsItem[0]);
          this.newsItem.shift();
          break;
        case 'prev':
          this.newsItem.unshift(lastItem);
          this.newsItem.pop();
          break;
        default:
      }
    },
    handleResize() {
      this.window.width = window.innerWidth;
    },
  },
  mounted() {
    axios.get('/admin/api/v1/news/?offset=0&limit=10')
      .then((response) => {
        const newsData = response.data.news;
        this.newsItem = Object.values(newsData);
      })
      .catch((error) => {
        console.log(error);
        this.errored = true;
      });
    if (this.window.width >= 1080) {
      if (this.interval > 0) {
        const vm = this;
        setInterval(() => {
          vm.slide('next');
        }, vm.interval);
      }
    }
  },
  components: {
    LeftBackground,
    SliderControls,
  },
};
</script>

<style lang="scss" scoped>
$white: #FFFFFF;
$dark-gray: #2B3138;
$light-gray: #D5D6D7;
$purple: #8293E8;
$yellow: #F8A81B;
$green: #27AE60;
$blue:#4DB6BC;

.main-news {
  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    overflow: hidden;
  }

  &__list {
    display: flex;
    width: 100%;
    justify-content: space-between;
    overflow: hidden;
    padding: 0 10px;

    @media (max-width: 1080px) {
      flex-direction: column;
      width: 86%;
    }

    @media (max-width: 890px) {
      flex-direction: column;
      width: 100%;
    }

    @media (max-width: 480px) {
      padding: 0;
    }
  }

  &__item {
    width: 32%;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.2);
    border-radius: 20px;
    overflow: hidden;
    position: relative;
    background: $dark-gray;
    margin-bottom: 20px;
    z-index: 20;
    transition: 0.5s;
    height: 590px;

    @media (max-width: 1375px) {
      height: 576px;
    }

    @media (max-width: 1080px) {
      width: 100%;
      height: auto;
    }

    &:hover {
      box-shadow: 0px 8px 12px rgb(130, 147, 232, 0.2);
      background: $blue;

      .main-news__text,
      .main-news__name,
      .main-news__data {
        color: $white;
      }

      .main-news__link-text {
        color: $dark-gray;
      }
    }

  }

  &__link {
    color: $white;
    display: flex;
    height: 100%;
    flex-direction: column;

    &-text {
      text-transform: uppercase;
      font-family: 'montserratbold', sans-serif;
      font-size: 12px;
      line-height: 20px;
      color: $purple;
    }
  }

  &__name {
    text-transform: uppercase;
    font-family: 'montserratbold', sans-serif;
    font-size: 14px;
    line-height: 21px;
    color: $blue;

    @media (max-width: 1260px) {
      font-size: 13px;
    }
  }

  &__img {
    display: flex;
    width: 100%;
    height: 250px;

    &-pic {
      width: 100%;
      object-fit: cover;
    }

    @media (max-width: 1560px) {
      height: 240px;
    }

    @media (max-width: 1375px) {
      height: 210px;
    }

    @media (max-width: 1080px) {
      height: 300px;
    }

    @media (max-width: 890px) {
      height: 255px;
    }

    @media (max-width: 660px) {
      height: 225px;
    }

    @media (max-width: 450px) {
      height: 210px;
    }
  }

  &__bottom {
    padding: 35px 25px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 57%;

    @media (max-width: 1375px) {
      height: 64%;
    }

    @media (max-width: 1090px) {
      width: 100%;
      height: 68%;
    }

    @media (max-width: 660px) {
      height: auto;
    }

    @media (max-width: 480px) {
      padding: 35px 10px;
    }
  }

  &__desc {
    display: flex;
    flex-direction: column;
  }

  &__text {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: $light-gray;
    padding: 30px 0 35px;
    transition: 0.5s;
    -webkit-line-clamp: 3;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    height: 93px;
    margin: 0 0 20px 0;
  }

  &__details {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__data {
    font-family: 'montserratbold', sans-serif;
    font-size: 14px;
    line-height: 22px;
    color: #76797E;
  }
}

.list-move,
.list-enter-active,
.list-leave-active {
  transition: all .2s;
}

.list-enter-from,
.list-leave-to {
  opacity: 0;
  transform: scaleY(0.01) translate(0, 10px);
}

.list-leave-active {
  position: absolute;
}
</style>
