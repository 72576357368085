<template>
<li class="videos-slider__item">
  <div class="videos-slider__img">
    <img class="videos-slider__img-pic" alt="videos-slider_image" :src="videoSliderItem.picture">
  </div>
  <h3 class="videos-slider__text">
    {{videoSliderItem.title}}
  </h3>
  <video-icon class="videos-slider__icon"></video-icon>
</li>
</template>

<script>
import VideoIcon from '@/components/shared/sliders/VideoIcon.vue';

export default {
  props: {
    videoSliderItem: {
      type: Object,
      required: true,
    },
  },
  components: {
    VideoIcon,
  },
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
$white: #FFFFFF;
$dark-gray: #2B3138;
$light-gray: #D5D6D7;
$purple: #8293E8;
$yellow: #F8A81B;
$green: #27AE60;
$blue:#4DB6BC;

.videos-slider {
  &__text {
    color: $white;
    text-transform: uppercase;
    background: rgba(130, 147, 232, 0.6);
    width: 100%;
    padding: 10px 17px 28px;
    border-radius: 0px 0px 5px 5px;
    font-family: 'montserratbold', sans-serif;
    font-size: 14px;
    line-height: 22px;
    position: absolute;
    bottom: 0;
    -webkit-line-clamp: 2;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    height: 57px;

    .class-carbon & {
      background: rgb(85, 152, 207, 0.6);
    }

    @media (max-width: 1100px) {
      padding: 10px 10px 28px;
      font-size: 12px;
      line-height: 18px;
      height: 49px;
    }
  }

  &__img {
    height: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: center;

    &-pic {
      width: 100%;
      object-fit: cover;
      height: 100%;
    }
  }

  &__item {
    overflow: hidden;
    position: relative;
    z-index: 50;
    margin: 0 25px 0 0;
    width: 100%;
    border-radius: 10px;
    cursor: pointer;
    height: 234px;

    &:last-child {
      margin-right: 0;
    }

    @media (max-width: 720px) {
      width: 48%;
      margin: 0 0 25px 0;
    }

    @media (max-width: 480px) {
      height: 158px;
    }
  }
}
</style>
