<template>
<section class="products">
  <right-background />

  <div class="main-container products__container">
    <h1 class="title">
      Продукты
    </h1>

    <div class="products__content">
      <ul class="products__list">
        <li class="products__item" v-for="product, index in products"
         :key="index" v-scrollanimation>
          <router-link class="products__link" :to="{name: product.path}">
            <div class="products__img">
              <img class="products__img-pic" alt="products-image" :src="product.picture">
            </div>
            <div class="products__right">
              <div class="products__desc">
                <h2 class="products__name">
                  {{product.title}}
                </h2>
                <p class="products__text">
                  {{product.text}}
                </p>
              </div>
              <div class="products__link-text">
                Узнать больше ›
              </div>
            </div>
            <soon-stick  v-if="index===4 || index===5"></soon-stick>
          </router-link>
        </li>
      </ul>
    </div>
  </div>
</section>
</template>

<script>
import RightBackground from '@/components/shared/background/RightBg.vue';
import SoonStick from '@/components/shared/SoonStick.vue';

export default {
  data() {
    return {
      products: [{
        path: 'class',
        picture: '/images/product/agro.jpg',
        title: 'CLASS',
        text: 'Программная платформа для автоматизации деятельности участников рынка сельскохозяйственного страхования в области оценки состояния посевных площадей на различных этапах развития возделываемых культур, с целью оптимизации затрат и повышения эффективности при решении задач.',
      },
      {
        path: 'risc-agro',
        picture: '/images/product/risc-agro.jpg',
        title: 'RiskAgro',
        text: 'Информационно-аналитический онлайн-сервис в области агрострахования, который позволяет страховым компаниям оценивать риски страхования урожая, сопровождать договоры страхования, урегулировать убытки при наступлении страхового случая.',
      },
      {
        path: 'class-mobile',
        picture: '/images/product/mobile.jpg',
        title: 'СLASS.MOBILE',
        text: 'Мобильные приложения для автоматизированного сбора результатов проведения полевых экспериментов, сбора первичных данных по классификации сельскохозяйственных культур, а также сбора данных о состоянии полей, севооборота и посевов.',
      },
      {
        path: 'agro-metrika',
        picture: '/images/product/agrometric.jpg',
        title: 'AGROMETRIKA',
        text: 'Сервис «AGROMETRIKA» реализован на базе Системы CLASS, разработки компании Ctrl2GO, предназначен для автоматизации управления системой земледелия сельскохозяйственного предприятия и внутрихозяйственного учета земель сельскохозяйственного назначения.',
      },
      {
        path: 'class-mobile',
        picture: '/images/product/scoring.jpg',
        title: 'CLASS.SCORING',
        text: 'Аналитический онлайн-сервис в области сельскохозяйственного страхования и кредитования для проведения скоринговой оценки угодий заемщика на территории Индии. Сервис предоставляется по API.',
      },
      {
        path: 'agro-metrika',
        picture: '/images/product/deposit.jpg',
        title: 'AgroDeposit',
        text: 'Сервис предоставляет необходимый набор пространственных данных и удобные инструменты для удаленной работы с земельными участками в цифровом формате для обеспечения залоговой деятельности в банковской сфере при выдаче сельскохозяйственных кредитов.',
      },
        // {
        //   path: 'class-forest',
        //   picture: '/images/product/forestProd.jpg',
        //   title: 'CLASS.FOREST',
        //   text: 'Леса являются господствующей формой растительности на Земле.
        // Они считаются залогом существования биосферы и благополучия сопутствующих сред.',
        // },
        // {
        //   path: 'class-carbon',
        //   picture: '/images/product/carbon.jpg',
        //   title: 'CLASS.CARBON',
        //   text: 'Высокотехнологичный комплекс экологического контроля и измерения
        // углеродного баланса, позволяющий оценивать и регулярно мониторить секвестрационный
        // потенциал сельскохозяйственных земель и лесных территорий.',
        // },
      ],
    };
  },
  components: {
    RightBackground,
    SoonStick,
  },
};
</script>

<style lang="scss" scoped>
$white: #FFFFFF;
$dark-gray: #2B3138;
$light-gray: #C0C1C2;
$purple: #8293E8;
$yellow: #F8A81B;
$sky-blue:#5598CF;
$blue: #4DB6BC;
$coral: #FF7D75;

.products {
  &__list {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

  }

  &__item {
    width: 49%;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.2);
    border-radius: 20px;
    overflow: hidden;
    position: relative;
    background: $dark-gray;
    margin-bottom: 20px;
    z-index: 20;
    transition: 0.5s;

    &:hover {
      box-shadow: 0px 8px 12px rgb(130, 147, 232, 0.2);

      .products__text,
      .products__link-text {
        color: $white;
      }
    }

    &:nth-child(1) {
      .products__name::after {
        background-color: $blue;
      }
    }

    &:nth-child(2) {
      .products__name::after {
        background-color: #F8A81B;
      }
    }

    &:nth-child(3) {
      .products__name::after {
        background-color: $sky-blue;
      }
    }

    &:nth-child(4) {
      .products__name::after {
        background-color: #FF7D75;
      }
    }

    &:nth-child(5) {
      .products__name::after {
        background-color: #8293E8;
      }
      .products__link {
        pointer-events: none;
        cursor: default;
      }
      .products__link-text {
        display: none;
      }
    }

    &:nth-child(6) {
      .products__name::after {
        background-color: #F8A81B;
      }
      .products__link-text {
        display: none;
      }
      .products__link {
        pointer-events: none;
        cursor: default;
      }
    }

    @media (max-width: 808px) {
      width: 47%;
    }

    @media (max-width: 660px) {
      width: 100%;
    }
  }

  &__link {
    color: $white;
    display: flex;
    height: 100%;
    position: relative;

    &-text {
      text-transform: uppercase;
      font-family: 'montserratbold', sans-serif;
      font-size: 12px;
      line-height: 20px;
      color: $purple;
      &:nth-child(5), &:nth-child(6) {
        display: none;
      }
    }

    @media (max-width: 1080px) {
      flex-direction: column;
    }
  }

  &__name {
    font-family: 'montserratbold', sans-serif;
    font-size: 32px;
    line-height: 40px;

    &:after {
      content: "";
      display: block;
      width: 108px;
      height: 6px;
      position: absolute;
      transition: width 0.3s cubic-bezier(0.51, 0.51, 0.51, 0.51);
      margin-top: 7px;
    }

    @media (max-width: 770px) {
      font-size: 24px;
    }

    @media (max-width: 660px) {
      font-size: 32px;
    }

    @media (max-width: 360px) {
      font-size: 24px;
    }
  }

  &__img {
    display: flex;
    width: 45%;

    &-pic {
      width: 100%;
      object-fit: cover;
      transition: all 1s ease;
    }

    @media (max-width: 1275px) {
      width: 35%;
    }

    @media (max-width: 1080px) {
      width: 100%;
      height: 250px;
      border-radius: 20px;
    }

    @media (max-width: 770px) {
      height: 180px;
    }

    @media (max-width: 660px) {
      height: 230px;
    }

    @media (max-width: 450px) {
      height: 150px;
    }
  }

  &__right {
    width: 53%;
    padding: 35px 25px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @media (max-width: 1275px) {
      width: 65%;
    }

    @media (max-width: 1080px) {
      width: 100%;
      height: 57%;
    }

    @media (max-width: 660px) {
      height: auto;
    }
  }

  &__desc {
    display: flex;
    flex-direction: column;
  }

  &__text {
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: $light-gray;
    padding: 30px 0 35px;
    transition: 0.5s;

    @media (max-width: 475px) {
      font-size: 14px;
      line-height: 19px;
    }
  }
}

.before-enter {
  opacity: 0;
  transform: translateY(100px);
  transition: 1s;
}

.enter {
  opacity: 1;
  transform: translateY(0px);
}
</style>
