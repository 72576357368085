<template>
<section class="industries">
  <right-background />

  <div class="main-container industries__container">
    <h1 class="title">
      Отрасли
    </h1>

    <div class="industries__content">
      <ul class="industries__list">
        <li class="industries__item" v-for="industry, index in industries" :key="index">
          <router-link class="industries__link" :to="{ name: industry.path }">
            <div class="industries__head">
              <div class="industries__img">
                <img class="industries__img-pic" alt="industries-image" :src="industry.bgPic">
              </div>
              <div class="industries__icon">
                <img class="industries__icon-pic" alt="ear" :src="industry.iconPic">
              </div>
            </div>
            <div class="industries__name">
              <p class="industries__text">
                {{industry.text}}
              </p>
            </div>
          </router-link>
        </li>
      </ul>
    </div>
  </div>
</section>
</template>

<script>
import RightBackground from '@/components/shared/background/RightBg.vue';

export default {
  data() {
    return {
      industries: [{
        path: 'agriculture',
        bgPic: '/images/industry/field.jpg',
        iconPic: '/svg/industry/ear.svg',
        text: 'Сельское хозяйство',
      },
      {
        path: 'ins-finance',
        bgPic: '/images/industry/sprout.jpg',
        iconPic: '/svg/industry/umbrella.svg',
        text: 'Страхование и финансы',
      },
      {
        path: 'forestry',
        bgPic: '/images/industry/forest.jpg',
        iconPic: '/svg/industry/spruce.svg',
        text: 'Лесное хозяйство',
      },
      {
        path: 'ecology',
        bgPic: '/images/industry/ecology.jpg',
        iconPic: '/svg/industry/leafIndustry.svg',
        text: 'Экология',
      },
      ],
    };
  },
  components: {
    RightBackground,
  },
};
</script>

<style lang="scss" scoped>
$white: #FFFFFF;
$mint: linear-gradient(90deg, #5EBA9B -85.72%, #28B5BE 178.5%, #28B5BE 225.13%);
$coral: #FF7D75;

.industries {
  &__list {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    @media (max-width: 1051px) {
      justify-content: space-evenly;
    }

    @media (max-width: 600px) {
      justify-content: space-between;
    }
  }

  &__head {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 322px;

    @media (max-width: 670px) {
      height: 220px;
    }

  }

  &__img {
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;

    &-pic {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
      transition: all 1s ease;
    }
  }

  &__icon {
    position: absolute;
    text-align: center;

    &-pic {
      @media (max-width: 710px) {
        width: 60%;
      }

      @media (max-width: 360px) {
        width: 50%;
      }
    }
  }

  &__item {
    width: 24%;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.2);
    border-radius: 20px;
    overflow: hidden;
    position: relative;
    z-index: 20;

    &:hover .industries__img-pic {
      transform: scale(1.2);
      transition: all 1s ease;
    }

    &:hover .industries__name {
      background: $coral;
    }

    @media (max-width: 1051px) {
      width: 43%;
      margin-bottom: 30px;
    }

    @media (max-width: 600px) {
      width: 48%;
    }

    &__text {
      text-transform: uppercase;
    }

  }

  &__link {
    color: $white;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
  }

  &__name {
    background: $mint;
    padding: 16px;
    height: 78px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10;
    position: relative;
  }

  &__text {
    text-align: center;
    font-weight: 400;
    font-size: 18px;
    line-height: 26px;

    @media (max-width: 475px) {
      font-size: 14px;
      line-height: 19px;
    }

  }
}
</style>
