<template>
<transition name="modal">
  <div class="modal" v-if="showModal">
    <div class="modal__container">
      <slot></slot>
    </div>

    <base-button class="base-button_border" @click="$emit('closeModal')">
      <div class="modal__icon">
        <img class="modal__icon-pic" alt="banner-icon" src="/svg/close.svg">
      </div>
    </base-button>
  </div>
</transition>
</template>

<script>
import BaseButton from '@/components/shared/BaseButton.vue';

export default {
  props: {
    showModal: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  components: {
    BaseButton,
  },
};
</script>

<style lang="scss" scoped>
.modal {
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  transition: opacity 0.3s ease;
  align-items: center;

  &__container {
    width: 90%;
    margin: 0 auto;
    border-radius: 10px;
    position: relative;
    border: none;
    font-weight: 300;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.2);

    &.vertical {
      height: 100%;
    }

    .modal__screens & {
      box-shadow: none;
      width: 73%;
      border-radius: 5px;
      padding: 15px;
      .class-mining & {
        background-color: transparent;
      }

      @media (max-width: 1850px) {
          width: 75%;
      }

      @media (max-width: 1500px) {
        .class-mining & {
          width: 85%;
        }
      }
      @media (max-width: 1400px) {
        padding: 10px;
        border-radius: 3px;
        width: 85%;
      }

      @media (max-width: 1000px) {
        padding: 10px;
        border-radius: 3px;
        .class-mining & {
          width: 93%;
        }
      }

      @media (max-width: 480px) {
        width: 95%;
        padding: 5px;
        border-radius: 3px;
      }
    }

    .videos__modal & {
      max-width: 1440px;
      background-color: transparent;
      position: relative;
      padding-bottom: 23.25%;
      width: 70%;
      margin: 0 auto;

      @media (max-width: 2650px) {
        padding-bottom: 30.25%;
      }

      @media (max-width: 1850px) {
        max-width: 1120px;
        padding-bottom: 40.25%;
      }

      @media (max-width: 720px) {
        width: 95%;
      }
    }

    .gallery__modal & {
      box-shadow: none;
      background-color: transparent;
      position: relative;
      padding-bottom: 33%;
      margin: 0 auto;
      width: 70%;
      max-width: 1120px;

      .risc-agro &,
      .crop-production & {
        max-width: initial;
      }

      @media (max-width: 1920px) {
        padding-bottom: 45%;
      }

      @media (max-width: 1120px) {
        padding-bottom: 45.25%;
      }

      @media (max-width: 720px) {
        width: 75%;
        padding-bottom: 48%;
      }

      @media (max-width: 480px) {
        padding-bottom: 80%;
      }
    }
  }

  &__icon {
    width: 40px;

    &-pic {
      width: 100%;
    }

    @media (max-width: 720px) {
      width: 30px;
    }
  }
}

.base-button_border {
  position: absolute;
  right: 0;
  top: 50px;
  z-index: 100;

  .videos__modal & {
    top: 65px;
  }

  @media (max-width: 1100px) {
    top: 25px;
  }
}

.modal-enter-active,
.modal-leave-active,
.modal-in-enter-active,
.modal-in-leave-active,
.modal-out-enter-active,
.modal-out-leave-active {
  transition: .3s;
}

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}
</style>
