<template>
<base-button v-if="scrollY > 300" class="scroll__button" @click="scrollUp">
  <div class="scroll__icon">
    <img class="scroll__icon-pic" alt="scroll-icon" src="/svg/arrow-up.svg">
  </div>
</base-button>
</template>

<script>
import BaseButton from '@/components/shared/BaseButton.vue';

export default {
  components: {
    BaseButton,
  },
  data() {
    return {
      scrollTimer: 0,
      scrollY: 0,
    };
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll);
  },
  methods: {
    handleScroll() {
      if (this.scrollTimer) return;
      this.scrollTimer = setTimeout(() => {
        this.scrollY = window.scrollY;
        clearTimeout(this.scrollTimer);
        this.scrollTimer = 0;
      }, 100);
    },
    scrollUp() {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    },
  },
  unmounted() {
    window.removeEventListener('scroll', this.handleScroll);
  },
};
</script>

<style lang="scss" scoped>
$light-gray: #C5C5C5;

.scroll {

  &__button {
    display: inline-block;
    text-align: center;
    padding: 10px;
    position: fixed;
    bottom: 30px;
    right: 30px;
    z-index: 80;
    cursor: pointer;
    border: 2px solid $light-gray;
    background-color: transparent;
    transition: opacity .5s, visibility .5s;
    border-radius: 50%;

    &:hover {
      border: 2px solid $light-gray;
      background-color: transparent;
      box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.2);
    }
  }

  &__icon {
    width: 30px;

    &-pic {
      width: 100%;
      height: 100%;
      object-fit: cover;
      display: block;
    }
  }
}
</style>
