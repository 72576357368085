<template>
  <div class="promo description__title_blue">
    <div class="promo__header">
      <product-title  class="promo__title">{{descTitleTop}}</product-title>
      <div class="promo__image">
        <img class="promo__pic"
            alt="promo-image" src="/svg/mainSlider/snow-mini.svg">
      </div>
    </div>

  <only-text class="promo__text"
    :onlyText="textTop" />
    <ul class="promo__list check-list__item_full-width">
        <check-list v-for="desc in description" :key="desc" :checkListItem="desc" />
    </ul>
    <div class="promo__text promo__text-bold">
      По вопросам подключения к&nbsp;сервису обращать к&nbsp;Евгению Александрову
      по&nbsp;электронной почте
       <a class="promo__link"  href="mailto:e.aleksandrov@ctrl2go.com">
        e.aleksandrov@ctrl2go.com
      </a>
       и&nbsp;телефону
       <a class="promo__link"  href="tel:+79104004407">+7&nbsp;910&nbsp;400&nbsp;44&nbsp;07</a>
    </div>
    <div class="promo__text promo__text-gray">
      *&nbsp;RiskAgro является частью платформы CLASS.PRO
       и&nbsp;имеет статус российского ПО&nbsp;Минцифры России</div>
  </div>
  </template>

<script>
import ProductTitle from '@/components/shared/ProductTitle.vue';
import OnlyText from '@/components/shared/OnlyText.vue';
import CheckList from '@/components/shared/listing/CheckList.vue';

export default {
  components: {
    ProductTitle,
    OnlyText,
    CheckList,
  },
  props: {
    picture: {
      type: String,
      default() {
        return '';
      },
    },
    isLink: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      descTitleTop: 'Онлайн сервис RiskAgro<br> для&nbsp;сельскохозяйственного сезона 2024&nbsp;года',
      textTop: 'С&nbsp;декабря 2023&nbsp;года по&nbsp;январь 2024&nbsp;года компания Ctrl2GO проводит Новогоднюю акцию на&nbsp;подключение к&nbsp;сервису RiskAgro* в&nbsp;2024&nbsp;году. В&nbsp;2023&nbsp;году компанией Ctrl2GO значительно усилила функционал сервиса RiskAgro новыми компонентами:',
      description: [{
        text: 'Раздел отображения режимов введения&nbsp;ЧС в&nbsp;целях обеспечения выполнения закона о&nbsp;страховании посевов&nbsp;от&nbsp;ЧС.',
      },
      {
        text: 'Функция автоматического выделения контуров сельхозугодий с&nbsp;использованием нейронных сетей по&nbsp;космическим снимкам Sentinel-2.',
      },
      {
        text: 'Интерактивная визуализация климатических картограмм с&nbsp;использованием данных Росгидромета.',
      },
      ],
    };
  },
};
</script>

  <style lang="scss" scoped>
  @import "/src/assets/css/modal.scss";

  .promo {
    padding: 40px 25px 30px;
    @media (max-width: 550px) {
        overflow: auto;
      }

    &__header  {
      display: flex;
      justify-content: space-between;
    }

    &__title {
      @media (max-width: 500px) {
        font-size: 15px;
      }
    }

    &__image {
      margin: 0 0 0 15px;
      @media (max-width: 700px) {
        width: 100px;
      }

      @media (max-width: 480px) {
        display: none;
      }
    }

    &__pic {
      width: 100%;
    }

    &__list {
      margin: 0 0 20px 20px;
      @media (max-width: 550px) {
        margin: 0 0 20px;
      }
    }

    &__text {
      &-bold {
        font-family: 'montserratbold', sans-serif;
        margin: 0 0 30px;
        line-height: 20px;
        font-size: 16px;
      }
    &-gray {
      color: #C5C5C5;
      font-size: 13px;
    }
    }
    &__link {
      color: #4db6bc;
    }
  }

  </style>
